export enum AccessByRole {
  all = "all",
  read = "read",
  write = "write",
  writeModele = "writeModele",
  UpdateTrigramme = "UpdateTrigramme",
  AccesMKT_Admin = "AccesMKT_Admin",
  isContry_France = "isContry_France"
}

export enum AccessPerPage{
  menu = "AllMenu",
  Standar = "Standar",
  CDR = "CDR"
}
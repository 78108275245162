import { Suspense, lazy, ReactNode, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { User } from './models/user.model';

import { CURRENT_USER, TOKEN_USER, TOKEN_USER_ORI } from './constants';
import { jwt } from './utils/jwt';
import ASMAlert from './components/Alert';
import { useTranslation } from 'react-i18next';
import { userHasAccessToPage } from './utils/access';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

function RolesAuthRoute({ children, page }: { children: ReactNode, page: string }) {
  const currentUser: User = JSON.parse(localStorage.getItem(CURRENT_USER)) || null;
  const isTokenExpired = jwt.isExpired();


  const { t } = useTranslation();
  if (isTokenExpired) {
    localStorage.removeItem(TOKEN_USER);
    localStorage.removeItem(TOKEN_USER_ORI);
    localStorage.removeItem(CURRENT_USER);
    return (
      <>
        <ASMAlert message={t('action.expiredSession')} severity="error" open={true} />
        <Navigate to="/" replace></Navigate>
      </>
    )
  }

  if (currentUser === null) {
    return (<Navigate to="/" replace></Navigate>)
  }
  const canAccess = userHasAccessToPage(page);
  if (canAccess) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Status403>
      </Status403>
    </Fragment>
  );
}
// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));
const Login = Loader(lazy(() => import('src/content/applications/Login')));
// Annonces 
const Annonces = Loader(lazy(() => import('src/content/pages/Components/ListAnnonces')));
const AnnonceCorp = Loader(lazy(() => import('src/content/pages/Components/ListAnnonces/CorpAnnonce')));
const CreatedAnnonce = Loader(lazy(() => import('src/content/pages/Components/ListAnnonces/CreateAnnonce')));
const Previsualise = Loader(lazy(() => import('src/content/pages/Components/ListAnnonces/Previsualise')));


//Modèles
const Models = Loader(lazy(() => import('src/content/pages/Components/Modele')));
const ModelsCorp = Loader(lazy(() => import('src/content/pages/Components/Modele/CorpModele')));
const ModificationTitreBlock = Loader(lazy(() => import('src/content/pages/Components/ModificationBlock')));
const ModificationListBlock = Loader(lazy(() => import('src/content/pages/Components/ModificationListBlock')));
const CreatedModele = Loader(lazy(() => import('src/content/pages/Components/Modele/CreateModele')));


// Elément
const ListElement = Loader(lazy(() => import('src/content/pages/Components/ListElement')));
const PreexistingListItem = Loader(lazy(() => import('src/content/pages/Components/PreexistingListItem')));
const CreatedListItem = Loader(lazy(() => import('src/content/pages/Components/CreatedListItem')));
const ListLog = Loader(lazy(() => import('src/content/pages/Components/ListLog')));

// Entreprise
const TableEntreprise = Loader(lazy(() => import('src/content/pages/Components/Entreprise/TableEntreprise')));
const EditEntreprise = Loader(lazy(() => import('src/content/pages/Components/Entreprise/EditEntreprise')));
const EditMarque = Loader(lazy(() => import('src/content/pages/Components/Entreprise/EditEntreprise/EditEntreprise')));
const CreateEntreprise = Loader(lazy(() => import('src/content/pages/Components/Entreprise/CreateEntreprise/CreateEntreprise')));
// Compte
const AccountList = Loader(lazy(() => import('src/content/pages/Components/AccountList')));
const CompteBUMenu = Loader(lazy(() => import('src/content/pages/Components/Accueil/ManagerMenu/CompteBUMenu')));
const ListeBU = Loader(lazy(() => import('src/content/pages/Components/ListeBU')));
const ListeAgence = Loader(lazy(() => import('src/content/pages/Components/ListeAgence')));
const ListePays = Loader(lazy(() => import('src/content/pages/Components/ListePays')));

// Applications

const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));


// Components

const Buttons = Loader(lazy(() => import('src/content/pages/Components/Buttons')));
const Modals = Loader(lazy(() => import('src/content/pages/Components/Modals')));
const Accordions = Loader(lazy(() => import('src/content/pages/Components/Accordions')));
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(lazy(() => import('src/content/pages/Components/Badges')));
const Tooltips = Loader(lazy(() => import('src/content/pages/Components/Tooltips')));
const Avatars = Loader(lazy(() => import('src/content/pages/Components/Avatars')));
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));

const Accueil = Loader(lazy(() => import('src/content/pages/Components/Accueil')));



// Status
const Status403 = Loader(lazy(() => import('src/content/pages/Status/Status403')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));


const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/accueil',
        element:

          <Overview />
      },
      {
        path: 'overview',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'management',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/competence"
            replace
          />
        )
      },


      {
        path: 'profile',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="details"
                replace
              />
            )
          },
          // {
          //   path: 'details',
          //   element: <UserProfile />
          // },
          // {
          //   path: 'settings',
          //   element: <UserSettings />
          // },
        ]
      }
    ]
  },
  {
    path: 'Annonces',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'AnnonceList',
        element:
        <RolesAuthRoute  page={'Standar'} >
          <Annonces />
          </RolesAuthRoute>

      },
      {
        path: 'AnnonceCorp/:id',
        element:
          <RolesAuthRoute page={'Standar'} >
            <AnnonceCorp />
          </RolesAuthRoute>

      },
      {
        path: 'Previsualise/:id',
        element:
          <RolesAuthRoute page={'Standar'} >
            <Previsualise />
          </RolesAuthRoute>

      },
      {
        path: 'CreatedAnnonce/:id',
        element:
          <RolesAuthRoute page={'Standar'} >
            <CreatedAnnonce />
          </RolesAuthRoute>
      }
    ]
  },
  {
    path: 'Log',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'ListLog',
        element: <ListLog />
      },

    ]
  },
  {
    path: 'Modeles',
    element: (
      <SidebarLayout />
    ),
    children: [
      {

        path: 'ModeleList',
        element:
          <RolesAuthRoute page={'Standar'} >
            <Models />
          </RolesAuthRoute>

      },
      {
        path: 'ModeleCorp/:id',
        element:
          <RolesAuthRoute page={'Standar'} >
            <ModelsCorp />
          </RolesAuthRoute>
      },
      {
        path: 'CreatedModele',
        element:
          <RolesAuthRoute page={'CDR'} >
            <CreatedModele />
          </RolesAuthRoute>
      },
      {
        path: 'ModificationBloc/:id',
        element:
          <RolesAuthRoute page={'CDR'} >
            <ModificationTitreBlock />
          </RolesAuthRoute>

      },
      {
        path: 'ModificationBlocCorp/:id',
        element:
          <RolesAuthRoute page={'CDR'} >
            <ModificationListBlock />
          </RolesAuthRoute>
      }
    ]
  },

  // Elément
  {
    path: 'items',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'list',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <ListElement />
          </RolesAuthRoute>

      },
      {
        path: 'preexisting/:type',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <PreexistingListItem />
          </RolesAuthRoute>
      },
      {
        path: 'created',
        element: <CreatedListItem />
      }
    ]
  },
  {
    path: 'entreprise',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'list',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <TableEntreprise />
          </RolesAuthRoute>

      },
      {
        path: 'edit',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <EditEntreprise />
          </RolesAuthRoute>
      },
      {
        path: 'create',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <CreateEntreprise />
          </RolesAuthRoute>

      },
      {
        path: 'modif',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <EditMarque />
          </RolesAuthRoute>

      }
    ]
  },
  {
    path: 'account',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'compteBUMenu',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <CompteBUMenu />
          </RolesAuthRoute>

      },
      {
        path: 'listeBU',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <ListeBU />
          </RolesAuthRoute>

      },
      {
        path: 'listeAgence',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <ListeAgence />
          </RolesAuthRoute>

      },
      {
        path: 'listePays',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <ListePays />
          </RolesAuthRoute>

      },
      {
        path: 'list',
        element:
          <RolesAuthRoute page={'AllMenu'} >
            <AccountList />
          </RolesAuthRoute>
      }
    ]
  },
  // 
  {
    path: 'page',
    element: (
      <SidebarLayout />

    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/components/buttons"
            replace
          />
        )
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },

      {
        path: 'accueil',
       
        element:
        <RolesAuthRoute page={'Standar'} >
        <Accueil />
        </RolesAuthRoute>
      },

    ]
  }
];

export default routes;
